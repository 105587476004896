import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

import { CheckCircleIcon } from "@heroicons/react/solid";
import { CheckIcon } from "@heroicons/react/solid";
import { BsCheck } from "react-icons/bs";

const presentingFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "Two full page ad in souvenir programme",
  "10 invites to the Gala Dinner",
  "Opportunities for Miss Singapore Queens to grace your company's event",
];
const goldFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "One Full page ad in souvenir programme",
  "10 invites to the Gala Dinner",
  "Opportunity for Miss Singapore Queens to grace your company's event",
];
const silverFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "One full page ad in souvenir programme",
  "4 invites to the Gala Dinner",
  "Opportunity for Miss Singapore Queens to grace your company's event",
];
const bronzeFeatures = [
  "Acknowledgement in all publicity materials on SWA website and on stage",
  "One full page ad in souvenir programme",
  "2 invites to the Gala Dinner",
  "Opportunity for Miss Singapore Queens to grace your company's event",
];

const Corporate = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Sponsorship opportunities"
          description="SWA welcome sponsorships
                      and financial support from individuals and corporate
                      member volunteers for our charity efforts."
        />
        <section className="max-w-5xl px-2 pt-6 mx-auto sm:w-4/5">
          <img src="https://source.unsplash.com/BCkLxilDvJU/1200x500" alt="" />
          <div className="bg-gray-100">
            <div className="pt-6 sm:pt-8 lg:pt-10">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-3xl font-extrabold text-swa-1 sm:text-4xl lg:text-5xl">
                    Corporate sponsorships 2023
                  </h2>
              
                </div>
              </div>
              <div className="px-4 mx-auto mt-8 max-w-7xl sm:px-6 lg:px-8">
                <div className="text-start">
                  
                  <div className="flex mt-4">
                    <div className="inline-flex mx-auto rounded-md shadow">
                      <Link
                        to="/forms/contact"
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-16 mt-8 bg-white sm:mt-12 sm:pb-20 lg:pb-28">
              <div className="relative">
                <div className="absolute inset-0 bg-gray-100 h-1/2" />
                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
                    <div className="flex-1 px-6 py-8 bg-white lg:p-12">
                      <h3 className="text-2xl font-extrabold text-swa-1 sm:text-3xl">
                        Presenting Sponsor
                      </h3>
                      <p className="mt-6 text-base text-gray-500">
                        Cash donations are eligible for 250% tax deduction.
                      </p>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 pr-4 text-sm font-semibold tracking-wider uppercase bg-white text-swa-1">
                            What's included
                          </h4>
                          <div className="flex-1 border-t-2 border-gray-200" />
                        </div>
                        <ul
                          role="list"
                          className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                        >
                          {presentingFeatures.map(feature => (
                            <li
                              key={feature}
                              className="flex items-start lg:col-span-1"
                            >
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="w-5 h-5 text-swa-1"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-sm text-gray-700">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="px-6 py-8 text-center bg-zinc-200 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                      {/* <p className="text-lg font-medium leading-6 text-gray-900">
                        Pay once, own it forever
                      </p> */}
                      <div className="flex items-center justify-center mt-4 text-5xl font-extrabold text-gray-900">
                        <span>$30,000</span>
                        <span className="ml-3 text-xl font-medium text-gray-500">
                          SGD
                        </span>
                      </div>
                      <p className="mt-4 text-sm">
                        {/* <a
                          href="#"
                          className="font-medium text-gray-500 underline"
                        >
                          Learn about our sponsorship terms
                        </a> */}
                      </p>
                      {/* <div className="mt-6">
                        <div className="rounded-md shadow">
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2Fsponsorreply.pdf?alt=media&token=0490ba67-d682-4867-b7b8-a388a8c8457e"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-gray-900"
                          >
                            Download form
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative mt-5">
                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
                    <div className="flex-1 px-6 py-8 bg-white lg:p-12">
                      <h3 className="text-2xl font-extrabold text-swa-1 sm:text-3xl">
                        Gold Sponsor
                      </h3>
                      <p className="mt-6 text-base text-gray-500">
                        Cash donations are eligible for 250% tax deduction.
                      </p>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 pr-4 text-sm font-semibold tracking-wider uppercase bg-white text-swa-1">
                            What's included
                          </h4>
                          <div className="flex-1 border-t-2 border-gray-200" />
                        </div>
                        <ul
                          role="list"
                          className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                        >
                          {goldFeatures.map(feature => (
                            <li
                              key={feature}
                              className="flex items-start lg:col-span-1"
                            >
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="w-5 h-5 text-swa-1"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-sm text-gray-700">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="px-6 py-8 text-center bg-zinc-200 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                      {/* <p className="text-lg font-medium leading-6 text-gray-900">
                        Pay once, own it forever
                      </p> */}
                      <div className="flex items-center justify-center mt-4 text-5xl font-extrabold text-gray-900">
                        <span>$10,000</span>
                        <span className="ml-3 text-xl font-medium text-gray-500">
                          SGD
                        </span>
                      </div>
                      <p className="mt-4 text-sm"></p>
                      {/* <div className="mt-6">
                        <div className="rounded-md shadow">
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2Fsponsorreply.pdf?alt=media&token=0490ba67-d682-4867-b7b8-a388a8c8457e"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-gray-900"
                          >
                            Download form
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative mt-5">
                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
                    <div className="flex-1 px-6 py-8 bg-white lg:p-12">
                      <h3 className="text-2xl font-extrabold text-swa-1 sm:text-3xl">
                        Silver Sponsor
                      </h3>
                      <p className="mt-6 text-base text-gray-500">
                        Cash donations are eligible for 250% tax deduction.
                      </p>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 pr-4 text-sm font-semibold tracking-wider uppercase bg-white text-swa-1">
                            What's included
                          </h4>
                          <div className="flex-1 border-t-2 border-gray-200" />
                        </div>
                        <ul
                          role="list"
                          className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                        >
                          {silverFeatures.map(feature => (
                            <li
                              key={feature}
                              className="flex items-start lg:col-span-1"
                            >
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="w-5 h-5 text-swa-1"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-sm text-gray-700">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="px-6 py-8 text-center bg-zinc-200 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                      {/* <p className="text-lg font-medium leading-6 text-gray-900">
                        Pay once, own it forever
                      </p> */}
                      <div className="flex items-center justify-center mt-4 text-5xl font-extrabold text-gray-900">
                        <span>$5,000&nbsp;&nbsp;</span>
                        <span className="ml-3 text-xl font-medium text-gray-500">
                          SGD
                        </span>
                      </div>
                      <p className="mt-4 text-sm">
                        {/* <a
                          href="#"
                          className="font-medium text-gray-500 underline"
                        >
                          Learn about our sponsorship terms
                        </a> */}
                      </p>
                      {/* <div className="mt-6">
                        <div className="rounded-md shadow">
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2Fsponsorreply.pdf?alt=media&token=0490ba67-d682-4867-b7b8-a388a8c8457e"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-gray-900"
                          >
                            Download form
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative mt-5">
                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg lg:max-w-none lg:flex">
                    <div className="flex-1 px-6 py-8 bg-white lg:p-12">
                      <h3 className="text-2xl font-extrabold text-swa-1 sm:text-3xl">
                        Bronze Sponsor
                      </h3>
                      <p className="mt-6 text-base text-gray-500">
                        Cash donations are eligible for 250% tax deduction.
                      </p>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 pr-4 text-sm font-semibold tracking-wider uppercase bg-white text-swa-1">
                            What's included
                          </h4>
                          <div className="flex-1 border-t-2 border-gray-200" />
                        </div>
                        <ul
                          role="list"
                          className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                        >
                          {bronzeFeatures.map(feature => (
                            <li
                              key={feature}
                              className="flex items-start lg:col-span-1"
                            >
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="w-5 h-5 text-swa-1"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-sm text-gray-700">
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="px-6 py-8 text-center bg-zinc-200 lg:flex lg:flex-col lg:justify-center lg:p-12">
                      {/* <p className="text-lg font-medium leading-6 text-gray-900">
                        Pay once, own it forever
                      </p> */}
                      <div className="flex items-center justify-center mt-4 text-5xl font-extrabold text-gray-900">
                        <span>$3,800&nbsp;&nbsp;</span>
                        <span className="ml-3 text-xl font-medium text-gray-500">
                          SGD
                        </span>
                      </div>
                      {/* <p className="mt-4 text-sm">
                        <a
                          href="#"
                          className="font-medium text-gray-500 underline"
                        >
                          Learn about our sponsorship terms
                        </a>
                      </p> */}
                      {/* <div className="mt-6">
                        <div className="rounded-md shadow">
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2Fsponsorreply.pdf?alt=media&token=0490ba67-d682-4867-b7b8-a388a8c8457e"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-gray-900"
                          >
                            Download form
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                <h2 className="text-xl text-gray-600 sm:text-xl">
                  <span className="block">
                    Other sponsorship types available
                  </span>
                </h2>
                <div className="flex mt-4">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/participate/advertisement"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium border border-transparent rounded-md text-swa-2 bg-swa-4 hover:bg-swa-3"
                    >
                      Advertisement
                    </Link>
                  </div>
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/participate/table"
                      className="inline-flex items-center justify-center px-5 py-3 ml-3 text-base font-medium border border-transparent rounded-md text-swa-2 bg-swa-4 hover:bg-swa-3"
                    >
                      Table sponsorship
                    </Link>
                  </div>
                </div>
                <h2 className="mt-8 text-lg text-gray-600 sm:text-lg">
                  <span className="block">
                    Please contact us if you have any questions regarding the
                    sponsorships
                  </span>
                </h2>
                <div className="flex mt-4">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/forms/contact"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Corporate;
